<template>
  <div class="pt-5 px-3">
    <div class="d-flex justify-content-center">
      <table class="table table-bordered table-responsive text-start d-table">
        <tr class="w-100">
          <td class="p-1">
            <b-img class="adjustWidth" :src="require('@/assets/images/logo/logo_mtca.png')" alt="logo" />
          </td>
          <td class="p-1">
            <h4 class="">PROCES VERBAL</h4>
            <h5>{{$t('demand_detail.committe')}}</h5>
          </td>
          <td class="p-1">
            <h6 class=""><strong>Reference:</strong> {{ reference }}</h6>
            <h6><strong>{{$t('demand_detail.requestor')}}</strong> {{ data.identity }}</h6>
          </td>
        </tr>
      </table>
    </div>
    <div class="d-flex justify-content-center">
      <table class="table table-bordered table-responsive table-striped w-100 d-table">
        <thead class="">
          <tr class="">
            <th class="p-1">Date</th>
            <th class="p-1">{{$t('demand_detail.state')}}</th>
            <th class="p-1">{{$t('demand_detail.author')}}</th>
          </tr>
        </thead>
        <tbody v-if="data && data.statuses != null">
          <tr class="" v-for="(state, index) in data.statuses" :key="index">
            <td class="">
              {{ data.created_at }}
            </td>

            <td class="">
              {{ status(state.name) }}
            </td>
            <td class="">
              {{ state.user != null ? state.user.fullname : "-" }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <b-row class="mt-5 d-flex justify-content-end">
      <b-col cols="12 " class="d-flex justify-content-end">
        <h4 class="pb-5 text-center">
          Signature <br /><small>(Nom et prénom suivi de la signature)</small>
        </h4>
      </b-col>
    </b-row> -->
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  BImg,
  VBToggle,
  BBadge,
  BSpinner,
  BModal,
  BTable,
} from "bootstrap-vue";
import statuses from "@/views/apps/formulaires/requests_states.js";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BImg,
    BBadge,
    BSpinner,
    BModal,
    BTable,
  },
  props: {
    data: {
      required: true,
    },
    reference: {
      required: false,
      //   default: ()=>{{}}
    },
  },
  computed: {},

  mounted() { },
  methods: {
    status(code) {
      const status = this.statusMatching(code);
      return status;
    },
  },
};
</script>
<style scoped>
.adjustWidth {
  width: 100px;
  height: 30px;
}
</style>
